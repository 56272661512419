import './bootstrap';
import '../css/app.css';

import {createSSRApp, h} from 'vue';
import {createInertiaApp, router} from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import i18n from "./i18n";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faUserCircle,
    faCat,
    faCrow,
    faDog,
    faDolphin,
    faDove,
    faDuck,
    faElephant,
    faFishFins,
    faFrog,
    faHippo,
    faHorse,
    faKiwiBird,
    faMouseField,
    faOtter,
    faPig,
    faRabbit,
    faScreencast as fadScreencast,
    faShareFromSquare as fadShareFromSquare,
    faSheep,
    faSpinnerThird,
    faSquirrel,
    faTurtle,
    faWhale
} from "@fortawesome/pro-duotone-svg-icons";
import {
    faAddressCard as falAddressCard,
    faArrowLeft,
    faBooks as falBooks,
    faBuildings as falBuildings,
    faCalendarCheck as falCalendarCheck,
    faCalendarDays,
    faCalendars,
    faChevronDown as falChevronDown,
    faChevronRight as falChevronRight,
    faChevronUp as falChevronUp,
    faCircleInfo as falCircleInfo,
    faClipboardQuestion,
    faClockSeven,
    faEllipsis,
    faEnvelope as falEnvelope,
    faExclamationTriangle as falExclamationTriangle,
    faListOl,
    faTriangleExclamation as falTriangleExclamation,
    faMemoPad,
    faMessages as falMessages,
    faMicrophone,
    faMicrophoneSlash,
    faMoneyCheckDollarPen,
    faNewspaper as falNewspaper,
    faNote,
    faPalette,
    faPersonHarassing,
    faScreencast as falScreencast,
    faShareFromSquare as falShareFromSquare,
    faShieldKeyhole,
    faTags,
    faTicket,
    faTrashClock,
    faUser,
    faUsers as falUsers,
    faVideo,
    faVideoSlash,
    faWebhook,
    faWindowRestore,
} from "@fortawesome/pro-light-svg-icons";
import {
    faAddressCard,
    faAnglesUpDown,
    faArrowsUpDownLeftRight,
    faArrowTurnDownRight as fasArrowTurnDownRight,
    faArrowUpArrowDown as fasArrowUpArrowDown,
    faBars,
    faBooks as fasBooks,
    faBug,
    faBuildings as fasBuildings,
    faCheck as fasCheck,
    faCalendarCheck as fasCalendarCheck,
    faCalendarDays as fasCalendarDays,
    faCalendarDay,
    faCalendarWeek,
    faCalendarStar,
    faClock,
    faChartSimple,
    faChevronDown as fasChevronDown,
    faAngleLeft,
    faChevronUp,
    faCircle,
    faCircleCheck,
    faCircleExclamation as fasCircleExclamation,
    faDiamondHalfStroke,
    faEllipsis as fasEllipsis,
    faEllipsisVertical,
    faFile,
    faFileArrowUp,
    faFileSlash,
    faLink,
    faList,
    faListCheck,
    faMessages as fasMessages,
    faMicrophoneSlash as fasMicrophoneSlash,
    faNewspaper as fasNewspaper,
    faPlus as fasPlus,
    faPlusLarge,
    faSquareCaretRight,
    faStar,
    faTimes,
    faTriangleExclamation as fasTriangleExclamation,
    faUserGroupSimple,
    faUsers as fasUsers,
    faUsersGear,
    faUserPlus as fasUserPlus,
    faVideo as fasVideo,
    faVideoSlash as fasVideoSlash,
    faVolume as fasVolume,
    faXmarkCircle,
    faXmarkLarge,
} from '@fortawesome/pro-solid-svg-icons'
import {
    faAngleUp,
    faAngleDown,
    faArrowDown,
    faArrowPointer,
    faArrowRight,
    faArrowUp,
    faArrowUpArrowDown,
    faArrowTurnDownRight,
    faBadgeCheck,
    faBookmark,
    faBrowser,
    faCalendarCheck,
    faCalendarClock,
    faCalendarPlus,
    faCheck as farCheck,
    faCheckCircle,
    faCheckDouble,
    faChevronLeft as farChevronLeft,
    faChevronRight as farChevronRight,
    faClock as farClock,
    faCopy,
    faCircleChevronRight,
    faCircleChevronLeft,
    faCircleExclamation,
    faCircleInfo,
    faCircleXmark,
    faCirclePlus,
    faClipboardCheck,
    faClipboardListCheck,
    faComputerClassic,
    faDoorClosed,
    faDoorOpen,
    faDownload,
    faEnvelope,
    faEnvelopeOpen,
    faExclamationCircle,
    faExclamationTriangle as farExclamationTriangle,
    faFaceSmile,
    faGlobePointer,
    faHourglass,
    faInfoCircle,
    faLaptopMobile,
    faMessageExclamation,
    faMessagePlus,
    faMessageQuestion,
    faMessages as farMessages,
    faMinus,
    faNoteSticky,
    faPenToSquare,
    faPersonChalkboard,
    faPersonFromPortal,
    faPersonToDoor,
    faPersonWalkingDashedLineArrowRight,
    faPlus,
    faReply,
    faScreencast,
    faShareFromSquare,
    faSquare,
    faSquareCheck,
    faSquareXmark,
    faSubtitlesSlash,
    faTrash,
    faTrashCan,
    faUpRightFromSquare,
    faUserClock,
    faUserPlus,
    faUsers as farUsers,
    faVideo as farVideo,
    faVolume,
    faXmark,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
    faAddressCard,
    faBadgeCheck,
    faTrash,
    faCircleXmark,
    faAngleDown,
    faAngleUp,
    faAnglesUpDown,
    faArrowDown,
    faArrowLeft,
    faArrowPointer,
    faArrowRight,
    faArrowTurnDownRight,
    faArrowUp,
    faArrowUpArrowDown,
    faArrowsUpDownLeftRight,
    faBars,
    faBookmark,
    faBrowser,
    faBug,
    faCalendarCheck,
    faCalendarClock,
    faCalendarDays,
    faCalendarPlus,
    faCalendarDay,
    faCalendarWeek,
    faCalendars,
    faCalendarStar,
    faCat,
    faChartSimple,
    faCheckCircle,
    faCheckDouble,
    faAngleLeft,
    faChevronUp,
    faCircle,
    faCircleCheck,
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleExclamation,
    fasCircleExclamation,
    faCircleInfo,
    faClipboardQuestion,
    faClipboardCheck,
    faClipboardListCheck,
    faClock,
    faClockSeven,
    faCrow,
    faCirclePlus,
    faComputerClassic,
    faCopy,
    faDiamondHalfStroke,
    faDog,
    faDolphin,
    faDoorClosed,
    faDoorOpen,
    faDove,
    faDownload,
    faDuck,
    faElephant,
    faEllipsis,
    faEllipsisVertical,
    faEnvelope,
    faEnvelopeOpen,
    faExclamationCircle,
    faFaceSmile,
    faFile,
    faFileArrowUp,
    faFileSlash,
    faFishFins,
    faFrog,
    faGlobePointer,
    faHippo,
    faHorse,
    faHourglass,
    faInfoCircle,
    faKiwiBird,
    falAddressCard,
    faLaptopMobile,
    falCircleInfo,
    faLink,
    faList,
    faListCheck,
    faListOl,
    faMemoPad,
    faMessageExclamation,
    faMessagePlus,
    faMessageQuestion,
    faMicrophone,
    faMicrophoneSlash,
    faMinus,
    faMoneyCheckDollarPen,
    faMouseField,
    faNote,
    faNoteSticky,
    faOtter,
    faPalette,
    faPenToSquare,
    faPersonChalkboard,
    faPersonHarassing,
    faPersonFromPortal,
    faPersonToDoor,
    faPersonWalkingDashedLineArrowRight,
    faPig,
    faPlus,
    faPlusLarge,
    faRabbit,
    faReply,
    faScreencast,
    faShareFromSquare,
    faSheep,
    faShieldKeyhole,
    faSpinnerThird,
    faSubtitlesSlash,
    faSquare,
    faSquareCaretRight,
    faSquareCheck,
    faSquareXmark,
    faSquirrel,
    faStar,
    faTags,
    faTicket,
    faTimes,
    faTrashCan,
    faTrashClock,
    faTurtle,
    faUpRightFromSquare,
    faUser,
    faUserCircle,
    faUserClock,
    faUserGroupSimple,
    faUserPlus,
    faUsersGear,
    faVideo,
    faVideoSlash,
    faWebhook,
    faWhale,
    faWindowRestore,
    faXmarkCircle,
    fadScreencast,
    fadShareFromSquare,
    falBooks,
    falBuildings,
    falCalendarCheck,
    falChevronDown,
    falChevronRight,
    falChevronUp,
    falEnvelope,
    falExclamationTriangle,
    falMessages,
    falNewspaper,
    falScreencast,
    falShareFromSquare,
    falTriangleExclamation,
    falUsers,
    farCheck,
    farChevronLeft,
    farChevronRight,
    farClock,
    farExclamationTriangle,
    farMessages,
    farUsers,
    farVideo,
    fasArrowUpArrowDown,
    fasBooks,
    fasBuildings,
    fasCalendarCheck,
    fasCalendarDays,
    fasCheck,
    fasChevronDown,
    fasEllipsis,
    fasMessages,
    fasNewspaper,
    fasPlus,
    fasTriangleExclamation,
    fasArrowTurnDownRight,
    fasUserPlus,
    fasUsers,
    fasVideo,
    fasVideoSlash,
    fasMicrophoneSlash,
    fasVolume,
    faVolume,
    faXmark,
    faXmarkLarge,
)
import * as Sentry from "@sentry/vue";

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

function inertiaRoutingInstrumentation(
    customStartTransaction,
    startTransactionOnPageLoad = true,
    startTransactionOnLocationChange = true,
) {
    let activeTransaction;
    let name;
    if (startTransactionOnPageLoad) {
        name = route().current();
        activeTransaction = customStartTransaction({
            name,
            op: 'pageload',
            metadata: {
                source: 'route',
            },
        });
    }

    if (startTransactionOnLocationChange) {
        router.on('before', (_to, _from) => {
            if (activeTransaction) {
                activeTransaction.finish();
            }

            const newName = route().current();

            if (newName !== name) {
                activeTransaction = customStartTransaction({
                    name: newName,
                    op: 'navigation',
                    metadata: {
                        source: 'route',
                    },
                });
            }
        });

        router.on('finish', () => {
            activeTransaction.setName(route().current(), 'route');
        });
    }
}

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

const app = createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createSSRApp({ render: () => h(App, props) })

        if (import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
            Sentry.init({
                app,
                dsn: "https://17d0ecd9cce69e1da2b0012046fd3703@o4505871780478976.ingest.sentry.io/4505871801122816",
                integrations: [
                    Sentry.browserTracingIntegration({
                        idleTimeout: 3000,
                        routingInstrumentation: inertiaRoutingInstrumentation,
                        _experiments: {
                            enableInteractions: true,
                        },
                    }),
                    Sentry.replayIntegration(),
                ],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 0.1,

                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/trygg\.chat\//],

                // Capture Replay for 10% of all sessions,
                // plus for 100% of sessions with an error
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
            });
        }

        app.component('font-awesome-icon', FontAwesomeIcon)
            .use(plugin)
            .use(i18n)
            .use(ZiggyVue, Ziggy)
            .mount(el);

        app.directive('focus', {
            mounted(el) {
                el.focus();
            }
        });

        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
